<template>
  <dashboard-wrapper>
    <div class="loading-template" v-if="loading">
      <loading-indicator />
    </div>
    <div class="border-bottom">
      <div class="_flex _between flex-wrap py-4 px-6">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb p-0 m-0 bg-transparent tiny">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Mes oeuvres
              </li>
            </ol>
          </nav>
          <h2 class="bold mt-2">
            Toutes mes oeuvres
          </h2>
        </div>
        <button class="btn black" @click="$router.push('/dashboard/ajouter')" :disabled="!getUserData && !(getUserData.userData.type_user === 'artist')">
          <i class="far fa-plus"></i> Nouvelle oeuvre
        </button>
      </div>
    </div>
    <!--  -->
    <div class="py-8 px-6">
      <template v-if="getUserData && (getUserData.userData.type_user === 'artist')">
        <div class="mt-6 texted italic">
          {{ this.artworks.length }} oeuvre{{ this.artworks.length > 1 ? "s" : "" }}
        </div>
        <!--  -->
        <div class="row mt-4" v-if="artworks.length">
          <div class="col-12 col-sm-6 col-lg-4 col-xl-3 mb-6" v-for="artwork in artworks" :key="artwork.artwork_id">
            <div class="card rounded-0 artists_follow_card artwork-card">
              <div class="">
<!--                <div class="_flex mb-3 caption texted">
                  <a class="_flex mr-2" data-toggle="tooltip" data-placement="top" title="L'oeuvre n'est dans aucun panier">
                    <i class="far fa-shopping-bag mr-1"></i>0
                  </a>
                  <a class="_flex" data-toggle="tooltip" data-placement="top" title="L'oeuvre n'est dans aucune wishlist">
                    <i class="fas fa-heart mr-1"></i> 0
                  </a>
                </div>-->
                <router-link :to="`/dashboard/modifier/${artwork.artwork_id}`" class="artists_follow_img_link">
                  <div :style="`background-image:url(${ artwork.data_pictures ? getMainPictureArtwork(artwork.data_pictures) : getEmptyArtworkPicture } )`" class="artists_follow_img"></div>
                </router-link>
                <div class="mt-1">
                  <div class="texted">
                    <div class="italic bold">{{ artwork.name.toUpperCase() }}</div>
                    <div class="">Dimensions : {{ artwork.dim_width }} x {{ artwork.dim_height }} x {{ artwork.dim_thickness }} {{ artwork.dim_unit }}</div>
                    <div class="">Poids : {{ artwork.weight }} {{ artwork.weight_unit }}</div>
                    <div class="text-capitalize">{{ artwork.data_category.name }}</div>
                    <div class="my-1">{{ artwork.number_available_copies }} examplaire en vente&nbsp;
                      <i class="fas fa-pencil"></i>
                    </div>
                  </div>
                  <div class="subheading bold">{{ artwork.price }} {{ currency(artwork.currency_id) }}&nbsp;
                    <i class="fas fa-pencil"></i>
                  </div>
                  <div :class="{
                      activated: artwork.status == 'published',
                      in_creation: artwork.status == 'draft',
                      rejected: artwork.status == 'rejected',
                      waiting: artwork.status == 'pending',
                    }">
                    {{ getStatus(artwork.status) }}
                  </div>
                  <!--  -->
<!--                  <div class="border-top mt-4 pt-3 _flex _around">
                    <a href="#" data-toggle="tooltip" data-placement="bottom" title="Dupliquer">
                      <i class="fal fa-copy"></i>
                    </a>
                    <a href="#" data-toggle="tooltip" data-placement="bottom" title="Désactiver">
                      <i class="far fa-ban"></i>
                    </a>
                    <a href="#" data-toggle="tooltip" data-placement="bottom" title="Lancer la promotion">
                      <i class="far fa-percent"></i>
                    </a>
                    <a href="#" data-toggle="tooltip" data-placement="bottom" title="Masquer le prix">
                      <i class="far fa-eye-slash"></i>
                    </a>
                    <a href="#" data-toggle="tooltip" data-placement="bottom" title="Supprimer">
                      <i class="far fa-trash"></i>
                    </a>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="d-flex flex-column align-items-center justify-content-center py-20">
          <h5>Vous n'avez pas des oeuvres d'art</h5>
          <br />
          <button class="btn black" @click="$router.push('/dashboard/ajouter')">
            <i class="far fa-plus"></i> Nouvelle oeuvre
          </button>
        </div>
      </template>
      <template v-if="!getUserData && !(getUserData.userData.type_user === 'artist')">
        <dashboard-noprofile />
      </template>
    </div>
  </dashboard-wrapper>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { supabase, user_id } from "@/lib/supabase";

export default {
  data: () => ({
    artworks: "",
    searchTerm: "",
    notFound: false,
    loading: false,
  }),
  computed: {
    ...mapGetters([
      "dashboard_artworks",
      "getToken",
      "getUserData",
      "getEmptyUserPicture",
      "getEmptyArtworkPicture",
    ]),
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([
        'axiosGetOneData'
    ]),
    currency(value) {
      if (!value) return "";
      switch (value) {
        case "usd":
          return "$";
        case "euro":
          return "€";
        case "sterling":
          return "£";
        default:
          return "";
      }
    },
    getStatus(status) {
      switch (status) {
        case "pending":
          return "En attente";
        case "published":
          return "En ligne";
        case "draft":
          return "En cours de création";
        case "rejected":
          return "Rejetée";
        case "inactive":
          return "Inactive";
        default:
          return "";
      }
    },
    getMainPictureArtwork(arr_pictures){
      let mainPicture = arr_pictures.filter(picture => parseInt(picture.artwork_picture_id) === 0)[0]
      return mainPicture.src
    }
  },
  mounted() {},
  created() {
    this.loading = true;
    this.axiosGetOneData({table: "retrieveArtworksByArtist", table_id: this.getToken})
        .then( response => {
          if(response.status === 200){
            this.artworks = response.data.data
            console.log(this.artworks)
            /*this.$toast.success(response.data.message, {
              icon: "fas fa-2x fa-check-circle",
            })*/
          }else {
            /*this.$toast.error(response.data.message, {
              icon: "fas fa-2x fa-times-circle",
            })*/
          }
          this.loading = false;
        })
        .catch()
  },
};
</script>
